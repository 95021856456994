import { Box, Line } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { useMaterial } from "./helpers/useMaterial";
import { LOD } from "./helpers/LOD";
import { ModuleComponent } from "./types";
import { useAtomValue } from "jotai";
import { stationModuleNeighborsState } from "../../state/stationModuleNeighborsState";

interface BracesProps {
  lineWidth: number;
}

const S = 3;
const T = 0.33;

const Braces: FC<BracesProps> = ({ lineWidth }) => (
  <group scale={[1, 20 / 16, 1]}>
    <Line
      points={[
        [S, -8, S],
        [S, -4, -S],
        [S, 0, S],
        [S, 4, -S],
        [S, 8, S],
      ]}
      lineWidth={lineWidth}
      color={Colors.gray600}
    />
    <Line
      points={[
        [-S, -8, -S],
        [-S, -4, S],
        [-S, 0, -S],
        [-S, 4, S],
        [-S, 8, -S],
      ]}
      lineWidth={lineWidth}
      color={Colors.gray600}
    />
    <Line
      points={[
        [-S, -8, -S],
        [S, -4, -S],
        [-S, 0, -S],
        [S, 4, -S],
        [-S, 8, -S],
      ]}
      lineWidth={lineWidth}
      color={Colors.gray600}
    />
    <Line
      points={[
        [S, -8, S],
        [-S, -4, S],
        [S, 0, S],
        [-S, 4, S],
        [S, 8, S],
      ]}
      lineWidth={lineWidth}
      color={Colors.gray600}
    />
  </group>
);

interface FrameProps {
  length: number;
}

const Frame: FC<FrameProps> = ({ length }) => {
  const chrome = useMaterial({ color: Colors.chrome });
  return (
    <>
      <mesh position={[S, 0, S]}>
        <Box args={[T, length, T]} material={chrome} />
      </mesh>
      <mesh position={[-S, 0, S]}>
        <Box args={[T, length, T]} material={chrome} />
      </mesh>
      <mesh position={[S, 0, -S]}>
        <Box args={[T, length, T]} material={chrome} />
      </mesh>
      <mesh position={[-S, 0, -S]}>
        <Box args={[T, length, T]} material={chrome} />
      </mesh>
    </>
  );
};

const Cap: FC = () => {
  const material = useMaterial({ color: Colors.gray50 });
  return <Box args={[(S + T) * 2, T, (S + T) * 2]} material={material} />;
};

export const Girder: ModuleComponent = ({ indices }) => {
  const neighbors = useAtomValue(
    stationModuleNeighborsState({ indices, relative: "local" })
  );

  return (
    <group>
      <Dimensions x={20} y={20} z={20} />

      <Frame length={20} />

      <LOD>
        <Braces lineWidth={3} />
        <Braces lineWidth={2} />
        <Braces lineWidth={1} />
      </LOD>

      {neighbors.front && (
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <group position={[0, 10 - S - T, 0]}>
            <Frame length={10 - S} />
          </group>
        </group>
      )}

      {neighbors.back && (
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, 10 - S - T, 0]}>
            <Frame length={10 - S} />
          </group>
        </group>
      )}

      {neighbors.left && (
        <group rotation={[0, 0, -Math.PI / 2]}>
          <group position={[0, 10 - S - T, 0]}>
            <Frame length={10 - S} />
          </group>
        </group>
      )}

      {neighbors.right && (
        <group rotation={[0, 0, Math.PI / 2]}>
          <group position={[0, 10 - S - T, 0]}>
            <Frame length={10 - S} />
          </group>
        </group>
      )}

      {neighbors.top?.kind === "CommunicationsArray" && (
        <mesh position={[0, 10, 0]}>
          <Cap />
        </mesh>
      )}

      {neighbors.bottom?.kind === "CommunicationsArray" && (
        <mesh position={[0, -10, 0]}>
          <Cap />
        </mesh>
      )}
    </group>
  );
};
