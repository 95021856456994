import { memoize } from "lodash";

export const toHex = memoize((text: string): string => {
  let hexString = "";
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    const hexValue = charCode.toString(16).padStart(2, "0"); // Ensure two digits
    hexString += hexValue;
  }
  return hexString.toUpperCase();
});
