import { FC } from "react";
import { LOD } from "./LOD";
import { Cylinder, Edges } from "@react-three/drei";
import { Colors } from "../colors";
import { useMaterial } from "./useMaterial";

const SEGMENTS_HIGH = 16;
const SEGMENTS_MEDIUM = 12;
const SEGMENTS_LOW = 8;

export const Connector: FC = () => {
  const gray200 = useMaterial({ color: Colors.gray200, side: 2 });
  return (
    <LOD>
      <Cylinder args={[5, 5, 20, SEGMENTS_HIGH, 1]} material={gray200}>
        <Edges />
      </Cylinder>

      <Cylinder args={[5, 5, 20, SEGMENTS_MEDIUM, 1]} material={gray200}>
        <Edges />
      </Cylinder>

      <Cylinder args={[5, 5, 20, SEGMENTS_LOW, 1]} material={gray200}>
        <Edges />
      </Cylinder>
    </LOD>
  );
};
