export type Indices = [number, number, number];

export const StationModuleKinds = [
  "Airlock",
  "Junction",
  "Section",
  "Biome",
  "Factory",
  "Girder",
  "Reactor",
  "SolarPanel",
  "HeatSink",
  "Engine",
  "Tank",
  "CommunicationsArray",
  "RadiationShield",
] as const;

export type StationModuleKind = (typeof StationModuleKinds)[number];

export type StationModule = {
  kind: StationModuleKind;
  // [X, Y, Z] indices on the layout unit grid
  indices: Indices;
};

export type Station = {
  name: string;
  modules: StationModule[];
  rotation?: [number, number, number];
};

// Rendering

export type Coordinates = [number, number, number];

export type Rotation = [number, number, number];

export type WorldToLocalOrientation =
  | "top-to-top"
  | "top-to-bottom"
  | "top-to-right"
  | "top-to-left"
  | "top-to-front"
  | "top-to-back"
  | "clockwise";

export type Neighbors<T> = {
  top: T;
  bottom: T;
  left: T;
  right: T;
  front: T;
  back: T;
};

export type NeighborIndices = Neighbors<Indices>;

export type NeighborKinds = Neighbors<StationModuleKind | undefined>;
