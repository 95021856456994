import { Edges, Line, Plane } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { useMaterial } from "./helpers/useMaterial";
import { LOD } from "./helpers/LOD";

interface BracesProps {
  lineWidth: number;
}

const Braces: FC<BracesProps> = ({ lineWidth }) => (
  <group>
    <Line
      points={[
        [-10, 10, 0],
        [10, 10, 0],
      ]}
      lineWidth={3}
      color={Colors.gray500}
    />

    <Line
      points={[
        [-10, -10, 0],
        [10, -10, 0],
      ]}
      lineWidth={3}
      color={Colors.gray500}
    />

    <Line
      points={[
        [-10, 0, 0],
        [10, 0, 0],
      ]}
      lineWidth={3}
      color={Colors.gray500}
    />
  </group>
);

export const SolarPanel: FC = () => {
  const gold = useMaterial({ color: Colors.gold, side: 2 });
  return (
    <group rotation={[Math.PI / 4, 0, 0]}>
      <Dimensions x={20} y={20} z={20} />

      <LOD>
        <Braces lineWidth={3} />
        <Braces lineWidth={2} />
        <Braces lineWidth={1} />
      </LOD>

      <group scale={[20 / 16, 1, 0.5]}>
        <group position={[-6, 0, 0]}>
          <mesh rotation={[0, 0.64, 0]}>
            <Plane args={[5, 9, 1, 1]} position={[0, -5, 0]} material={gold}>
              <Edges />
            </Plane>
            <Plane args={[5, 9, 1, 1]} position={[0, 5, 0]} material={gold}>
              <Edges />
            </Plane>
          </mesh>
        </group>

        <group position={[-2, 0, 0]}>
          <mesh rotation={[0, -0.64, 0]}>
            <Plane args={[5, 9, 1, 1]} position={[0, -5, 0]} material={gold}>
              <Edges />
            </Plane>
            <Plane args={[5, 9, 1, 1]} position={[0, 5, 0]} material={gold}>
              <Edges />
            </Plane>
          </mesh>
        </group>

        <group position={[2, 0, 0]}>
          <mesh rotation={[0, 0.64, 0]}>
            <Plane args={[5, 9, 1, 1]} position={[0, -5, 0]} material={gold}>
              <Edges />
            </Plane>
            <Plane args={[5, 9, 1, 1]} position={[0, 5, 0]} material={gold}>
              <Edges />
            </Plane>
          </mesh>
        </group>

        <group position={[6, 0, 0]}>
          <mesh rotation={[0, -0.64, 0]}>
            <Plane args={[5, 9, 1, 1]} position={[0, -5, 0]} material={gold}>
              <Edges />
            </Plane>
            <Plane args={[5, 9, 1, 1]} position={[0, 5, 0]} material={gold}>
              <Edges />
            </Plane>
          </mesh>
        </group>
      </group>
    </group>
  );
};
