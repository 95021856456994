import { Box, Cone, Cylinder, Lathe, Sphere } from "@react-three/drei";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { range } from "lodash";
import * as THREE from "three";
import { LOD } from "./helpers/LOD";
import { useMaterial } from "./helpers/useMaterial";
import { ModuleComponent } from "./types";
import { useAtomValue } from "jotai";
import { stationModuleNeighborsState } from "../../state/stationModuleNeighborsState";

const SEGMENTS = 24;

const points: THREE.Vector2[] = range(0, 5).map(
  (i) => new THREE.Vector2(i * 2, i * i * 0.05 + i * 0.2 + 0.5)
);

export const CommunicationsArray: ModuleComponent = ({ indices }) => {
  const neighbors = useAtomValue(
    stationModuleNeighborsState({ indices, relative: "local" })
  );
  const white = useMaterial({ color: Colors.white, side: 2 });
  const gray200 = useMaterial({ color: Colors.gray200 });
  const chrome = useMaterial({ color: Colors.chrome, flatShading: true });

  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <group position={[0, 0, 0]}>
        <mesh rotation={[Math.PI, 0, Math.PI / 2]}>
          <LOD>
            <Lathe args={[points, SEGMENTS]} material={white} />
            <Lathe args={[points, SEGMENTS / 2]} material={white} />
            <Lathe args={[points, SEGMENTS / 3]} material={white} />
          </LOD>

          <Cone position={[0, 5, 0]} args={[0.2, 10, 3]} material={gray200} />
        </mesh>

        <Box args={[0.5, 20, 0.5]} material={chrome} />
        {!neighbors.top && (
          <>
            <Cylinder
              args={[0.5, 0.5, 40, 3, 1]}
              position={[1, 10, 1]}
              material={chrome}
            />

            <group position={[1, 30, 1]}>
              <pointLight color="#800" position={[0, 1, 0]} />
              <Sphere args={[0.5, 2, 2]}>
                <meshStandardMaterial color="red" emissive="red" />
              </Sphere>
            </group>
          </>
        )}
      </group>
    </>
  );
};
