import { atom } from "jotai";
import { stationState } from "./stationState";
import { mapKeys } from "lodash";
import { getKeyFromIndices } from "../utils/getKeyFromIndices";

export const stationModulesByKeyState = atom((get) =>
  mapKeys(get(stationState).modules, (stationModule) =>
    getKeyFromIndices(stationModule.indices)
  )
);
