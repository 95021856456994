import { FC, MouseEvent, memo, useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { mdiCog } from "@mdi/js";
import Icon from "@mdi/react";
import { isMenuOpenState } from "../state/isMenuOpenState";
import { areMenusExclusive } from "../utils/areMenusExclusive";
import { isPaletteOpenState } from "../state/isPaletteOpenState";

export const ToggleMenuButton: FC = memo(() => {
  const onClick = useAtomCallback(
    useCallback((get, set, e: MouseEvent) => {
      e.stopPropagation();

      set(isMenuOpenState, (isOpen) => !isOpen);

      if (get(isMenuOpenState) && areMenusExclusive()) {
        set(isPaletteOpenState, false);
      }
    }, [])
  );

  return (
    <button onClick={onClick}>
      <Icon path={mdiCog} size={0.7} />
    </button>
  );
});
