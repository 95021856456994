import { FC, MouseEvent, memo, useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { mdiFolderOpenOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { activeStationIndexState } from "../state/activeStationIndexState";
import { useAtomValue } from "jotai";

interface Props {
  name: string;
  index: number;
}

export const OpenStationButton: FC<Props> = memo(({ name, index }) => {
  const onClick = useAtomCallback(
    useCallback(
      (_get, set, e: MouseEvent) => {
        e.stopPropagation();

        set(activeStationIndexState, index);
      },
      [index]
    )
  );

  const isActive = useAtomValue(activeStationIndexState) === index;

  return (
    <button onClick={onClick} data-active={isActive}>
      <Icon path={mdiFolderOpenOutline} size={0.7} /> {name}
    </button>
  );
});
