import { ChangeEvent, FC } from "react";
import styles from "./DevTools.module.css";
import { useAtom } from "jotai";
import {
  areAxesVisibleState,
  isModuleOrientationHelperVisibleState,
} from "../state/devToolsState";

export const DevTools: FC = () => {
  const [areAxesVisible, setAreAxesVisible] = useAtom(areAxesVisibleState);
  const onChangeAxesVisibility = (event: ChangeEvent<HTMLInputElement>) => {
    setAreAxesVisible(event.target.checked);
  };

  const [
    isModuleOrientationHelperVisible,
    setIsModuleOrientationHelperVisible,
  ] = useAtom(isModuleOrientationHelperVisibleState);
  const onChangeModuleOrientationHelperVisibility = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsModuleOrientationHelperVisible(event.target.checked);
  };

  return (
    <div className={styles.container}>
      <label className={styles.toggle}>
        <input
          type="checkbox"
          checked={areAxesVisible}
          onChange={onChangeAxesVisibility}
        />
        Show world axes
      </label>

      <label className={styles.toggle}>
        <input
          type="checkbox"
          checked={isModuleOrientationHelperVisible}
          onChange={onChangeModuleOrientationHelperVisibility}
        />
        Show orientation helper
      </label>
    </div>
  );
};
