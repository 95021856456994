import { Station } from "../types";

export const DEFAULT_STATION: Station = {
  name: "Home",
  modules: [
    {
      kind: "Junction",
      indices: [0, 0, 0],
    },
    {
      kind: "Reactor",
      indices: [0, 1, 0],
    },
    {
      kind: "SolarPanel",
      indices: [1, 1, 0],
    },
    {
      kind: "SolarPanel",
      indices: [2, 1, 0],
    },
    {
      kind: "SolarPanel",
      indices: [3, 1, 0],
    },
    {
      kind: "SolarPanel",
      indices: [-1, 1, 0],
    },
    {
      kind: "SolarPanel",
      indices: [-2, 1, 0],
    },
    {
      kind: "SolarPanel",
      indices: [-3, 1, 0],
    },
    {
      kind: "Reactor",
      indices: [0, 2, 0],
    },
    {
      kind: "SolarPanel",
      indices: [1, 2, 0],
    },
    {
      kind: "SolarPanel",
      indices: [2, 2, 0],
    },
    {
      kind: "SolarPanel",
      indices: [3, 2, 0],
    },
    {
      kind: "SolarPanel",
      indices: [-1, 2, 0],
    },
    {
      kind: "SolarPanel",
      indices: [-2, 2, 0],
    },
    {
      kind: "SolarPanel",
      indices: [-3, 2, 0],
    },
    {
      kind: "Section",
      indices: [0, -1, 0],
    },
    {
      kind: "Girder",
      indices: [0, -2, 0],
    },
    {
      kind: "Girder",
      indices: [0, -3, 0],
    },
    {
      kind: "Girder",
      indices: [0, -4, 0],
    },
    {
      kind: "Girder",
      indices: [0, -5, 0],
    },
    {
      kind: "Reactor",
      indices: [0, -6, 0],
    },
  ],
};
