import { atomFamily, useAtomCallback } from "jotai/utils";
import { Indices } from "../types";
import { useCallback } from "react";
import { atom } from "jotai";
import { stationState } from "./stationState";
import { getKeyFromIndices } from "../utils/getKeyFromIndices";

export const destroyModuleState = atomFamily((indices: Indices) =>
  atom(null, (_get, set) => {
    console.log(`[construct]`, getKeyFromIndices(indices));
    set(stationState, ({ modules, ...station }) => ({
      ...station,
      modules: modules.filter((module) => module.indices !== indices),
    }));
  })
);

export const useDestroyModule = (indices: Indices) =>
  useAtomCallback(
    useCallback(
      (_get, set) => {
        set(destroyModuleState(indices));
      },
      [indices]
    )
  );
