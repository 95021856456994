import { FC, memo } from "react";
import styles from "./PaletteOverlay.module.css";
import { StationModuleKind, StationModuleKinds } from "../types";
import { PaletteButton } from "./PaletteButton";
import { SummaryDisplay } from "./SummaryDisplay";

export const PaletteOverlay: FC = memo(() => {
  return (
    <div className={styles.container}>
      <div data-header>
        <strong data-uppercase>Available Modules</strong>
      </div>

      {StationModuleKinds.map((kind: StationModuleKind) => (
        <PaletteButton key={kind} brush={kind} />
      ))}

      <div />
      <div />

      <div data-header>
        <strong data-uppercase>Station Status</strong>
      </div>
      <SummaryDisplay />
    </div>
  );
});
