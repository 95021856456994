import { Cylinder, Edges } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { Connector } from "./helpers/Connector";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS = 12;

const EndCap: FC = () => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <group position={[0, 7.5, 0]}>
      <Cylinder args={[7, 10, 4, SEGMENTS, 1]} material={gray100}>
        <Edges />
      </Cylinder>
    </group>
  );
};

export const Section: FC = () => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <Cylinder args={[10, 10, 15, SEGMENTS, 1]} material={gray100}>
        <Edges />
      </Cylinder>

      <EndCap />

      <group rotation={[0, 0, Math.PI]}>
        <EndCap />
      </group>

      <Connector />
    </>
  );
};
