import { FC, MouseEvent, memo, useCallback } from "react";
import { StationModuleKind } from "../types";
import {
  activeBrushState,
  isBrushActiveState,
} from "../state/activeBrushState";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import Icon from "@mdi/react";
import { startCase } from "lodash";
import { ICONS } from "./constants";

interface Props {
  brush: StationModuleKind;
}

const FastIcon = memo(Icon);

export const PaletteButton: FC<Props> = memo(({ brush }) => {
  const isActive = useAtomValue(isBrushActiveState(brush));
  const onClick = useAtomCallback(
    useCallback(
      (_get, set, e: MouseEvent) => {
        e.stopPropagation();
        console.log(`[set brush] ${brush}`);
        set(activeBrushState, brush);
      },
      [brush]
    )
  );

  return (
    <button onClick={onClick} data-active={isActive}>
      <FastIcon path={ICONS[brush]} size={0.7} />
      {startCase(brush)}
    </button>
  );
});
