import { Fragment, memo, useRef, useState } from "react";
import { FC, useCallback } from "react";
import * as THREE from "three";
import { Indices } from "../../types";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import { Box, Edges, MeshDiscardMaterial, Plane } from "@react-three/drei";
import { useDestroyModule } from "../../state/useDestroyModule";
import { useConstructModule } from "../../state/useConstructModule";
import { Group } from "three";
import { Axes } from "./helpers/Axes";
import { useAtomValue, useSetAtom } from "jotai";
import { stationModuleRotationState } from "../../state/stationModuleRotationState";
import { ModuleOrientationHelper } from "./ModuleOrientationHelper";
import { isModuleOrientationHelperVisibleState } from "../../state/devToolsState";
import { FocusIndicator } from "./helpers/FocusIndicator";
import { areInstructionsVisibleState } from "../../state/areInstructionsVisibleState";
import { ModuleTooltip } from "./ModuleTooltip";
import { ModuleTooltipContent } from "./ModuleTooltipContent";
import {
  isStationModuleSelectedState,
  useSelectStationModule,
  useStationModuleSelectionChanged,
} from "../../state";
import { useAtomCallback } from "jotai/utils";

interface Props {
  indices: Indices;
}

const INTERACTION_OFFSETS = {
  front: [0, 0, 1],
  back: [0, 0, -1],
  left: [-1, 0, 0],
  right: [1, 0, 0],
  top: [0, 1, 0],
  bottom: [0, -1, 0],
};

const INTERACTION_USER_DATA = {
  front: { offset: INTERACTION_OFFSETS.front },
  back: { offset: INTERACTION_OFFSETS.back },
  left: { offset: INTERACTION_OFFSETS.left },
  right: { offset: INTERACTION_OFFSETS.right },
  top: { offset: INTERACTION_OFFSETS.top },
  bottom: { offset: INTERACTION_OFFSETS.bottom },
};

const INTERACTION_PLANE: [number, number, number, number] = [20, 20, 1, 1];

const LONG_PRESS_DURATION = 500;

const SHOW_INTERACTIVES = false;

const SHOW_TOOLTIPS = false;

type InteractionState = {
  scale: number;
  lastUpdate: number;
  state: "running" | "idle";
};

export const ModuleController: FC<Props> = memo(({ indices }) => {
  const setAreInstructionsVisible = useSetAtom(areInstructionsVisibleState);
  const lastPointerDownRef = useRef<number>(Number.NaN);
  const focusRef = useRef<THREE.Mesh>(null);
  const axesRef = useRef<Group>(null);
  const [isHover, setHover] = useState(false);

  const interactionsRef = useRef<InteractionState>({
    scale: 0.5,
    lastUpdate: NaN,
    state: "idle",
  });

  const setIsHover = useCallback(
    (isHover: boolean) => {
      setHover(isHover);
      setAreInstructionsVisible(isHover);

      if (focusRef.current) {
        focusRef.current.visible = isHover;
      }

      if (axesRef.current) {
        axesRef.current.visible = isHover;
      }

      if (interactionsRef.current) {
        if (isHover && interactionsRef.current.state === "idle") {
          interactionsRef.current.scale = 0;
        }

        interactionsRef.current.state = isHover ? "running" : "idle";
      }
    },
    [setAreInstructionsVisible]
  );

  const onSelectionChanged = useCallback((isSelected: boolean) => {
    if (focusRef.current) {
      focusRef.current.visible = isSelected;
    }
  }, []);

  useStationModuleSelectionChanged(indices, onSelectionChanged);

  useFrame(({ clock }) => {
    if (
      interactionsRef.current?.state === "running" &&
      interactionsRef.current.scale < 1
    ) {
      if (isNaN(interactionsRef.current.lastUpdate)) {
        interactionsRef.current.scale = 0;
        interactionsRef.current.lastUpdate = clock.getElapsedTime();
      } else {
        const delta =
          clock.getElapsedTime() - interactionsRef.current.lastUpdate;

        interactionsRef.current.scale = Math.min(
          1,
          interactionsRef.current.scale + delta * 7
        );

        interactionsRef.current.lastUpdate = clock.getElapsedTime();
      }

      if (focusRef.current) {
        focusRef.current.scale.x = interactionsRef.current.scale;
        focusRef.current.scale.y = interactionsRef.current.scale;
        focusRef.current.scale.z = interactionsRef.current.scale;
      }
    }

    if (interactionsRef.current.state === "idle") {
      interactionsRef.current.lastUpdate = NaN;
    }
  });

  const onPointerOver = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      setIsHover(true);
    },
    [setIsHover]
  );

  const onPointerOut = useAtomCallback(
    useCallback(
      (get, _set, e: ThreeEvent<PointerEvent>) => {
        e.stopPropagation();
        if (!get(isStationModuleSelectedState(indices))) {
          setIsHover(false);
        }
      },
      [indices, setIsHover]
    )
  );

  const constructModule = useConstructModule(indices);
  const destroyModule = useDestroyModule(indices);

  const onPointerDown = useCallback((e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    lastPointerDownRef.current = Date.now();
  }, []);

  const onPointerMove = useCallback((e: ThreeEvent<PointerEvent>) => {
    lastPointerDownRef.current = NaN;
  }, []);

  const onPointerUp = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();

      const lastPointerDown = lastPointerDownRef.current;
      const duration = Date.now() - lastPointerDown;

      if (!isNaN(lastPointerDown) && duration > LONG_PRESS_DURATION) {
        destroyModule();
      }
    },
    [destroyModule]
  );

  const selectStationModule = useSelectStationModule(indices);
  const onClick = useCallback(
    (e: ThreeEvent<MouseEvent>) => {
      e.stopPropagation();

      selectStationModule();
    },
    [selectStationModule]
  );

  const onDoubleClick = useCallback(
    (e: ThreeEvent<MouseEvent>) => {
      e.stopPropagation();

      const { offset } = e.eventObject.userData;
      if (offset) {
        constructModule(offset);
      }
    },
    [constructModule]
  );

  const rotation = useAtomValue(stationModuleRotationState(indices));
  const isModuleOrientationHelperVisible = useAtomValue(
    isModuleOrientationHelperVisibleState
  );

  return (
    <Fragment>
      <Box
        args={[20, 20, 20]}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <MeshDiscardMaterial />
      </Box>

      <mesh ref={focusRef} visible={false}>
        <FocusIndicator />
      </mesh>

      {/* Module orientation devtools */}
      {isModuleOrientationHelperVisible && (
        <group rotation={rotation} ref={axesRef} visible={false}>
          <mesh position={[-10.1, -10.1, -10.1]}>
            <Axes forced size={20.2} />
          </mesh>

          {isHover && <ModuleOrientationHelper />}
        </group>
      )}

      {/* Tooltip */}
      {SHOW_TOOLTIPS && isHover && (
        <ModuleTooltip>
          <ModuleTooltipContent indices={indices} />
        </ModuleTooltip>
      )}

      {/* Front - no rotation necessary */}
      <mesh position={[0, 0, 10]}>
        <Plane
          args={INTERACTION_PLANE}
          userData={INTERACTION_USER_DATA.front}
          onPointerMove={onPointerMove}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
        >
          <MeshDiscardMaterial />
          {SHOW_INTERACTIVES && <Edges color="#0080ff" />}
        </Plane>
      </mesh>

      {/* Back */}
      <group rotation={[0, Math.PI, 0]}>
        <mesh position={[0, 0, 10]}>
          <Plane
            args={INTERACTION_PLANE}
            userData={INTERACTION_USER_DATA.back}
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          >
            <MeshDiscardMaterial />
            {SHOW_INTERACTIVES && <Edges color="#0000ff" />}
          </Plane>
        </mesh>
      </group>

      {/* Left */}
      <group rotation={[0, -Math.PI / 2, 0]}>
        <mesh position={[0, 0, 10]}>
          <Plane
            args={INTERACTION_PLANE}
            userData={INTERACTION_USER_DATA.left}
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          >
            <MeshDiscardMaterial />
            {SHOW_INTERACTIVES && <Edges color="#ff0000" />}
          </Plane>
        </mesh>
      </group>

      {/* Right */}
      <group rotation={[0, Math.PI / 2, 0]}>
        <mesh position={[0, 0, 10]}>
          <Plane
            args={INTERACTION_PLANE}
            userData={INTERACTION_USER_DATA.right}
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          >
            <MeshDiscardMaterial />
            {SHOW_INTERACTIVES && <Edges color="#ff8000" />}
          </Plane>
        </mesh>
      </group>

      {/* Top */}
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh position={[0, 0, 10]}>
          <Plane
            args={INTERACTION_PLANE}
            userData={INTERACTION_USER_DATA.top}
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          >
            <MeshDiscardMaterial />
            {SHOW_INTERACTIVES && <Edges color="#00ff00" />}
          </Plane>
        </mesh>
      </group>

      {/* Bottom */}
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh position={[0, 0, 10]}>
          <Plane
            args={INTERACTION_PLANE}
            userData={INTERACTION_USER_DATA.bottom}
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
          >
            <MeshDiscardMaterial />
            {SHOW_INTERACTIVES && <Edges color="#00ffcc" />}
          </Plane>
        </mesh>
      </group>
    </Fragment>
  );
});
