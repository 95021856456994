import { getCssVariable } from "../utils/getCssVariable";

const COLOR = getCssVariable("--ui-color-hover");
const FILL = getCssVariable("--sidebar-background-color");

const CURSOR = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" transform="matrix(-1,0,0,1,0,0)">
  <path d="M21,3L3,10.53V11.5L9.84,14.16L12.5,21H13.46L21,3Z" stroke="${COLOR}" fill="${FILL}"></path>
</svg>
`;

const RESIZE_ALL = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <path stroke="${COLOR}" fill="${FILL}" d="M13,11H18L16.5,9.5L17.92,8.08L21.84,12L17.92,15.92L16.5,14.5L18,13H13V18L14.5,16.5L15.92,17.92L12,21.84L8.08,17.92L9.5,16.5L11,18V13H6L7.5,14.5L6.08,15.92L2.16,12L6.08,8.08L7.5,9.5L6,11H11V6L9.5,7.5L8.08,6.08L12,2.16L15.92,6.08L14.5,7.5L13,6V11Z" />
</svg>`;

const styleElement = document.createElement("style");

const cssRule = `
  :root {
    --cursor: url("data:image/svg+xml,${encodeURIComponent(
      CURSOR
    )}") 0 0, pointer;

    --resize-all: url("data:image/svg+xml,${encodeURIComponent(
      RESIZE_ALL
    )}") 12 12, pointer;
  }

  body {
    cursor: var(--cursor);
  }
`;

styleElement.textContent = cssRule;
document.head.appendChild(styleElement);

export {};
