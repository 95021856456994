import { atomFamily } from "jotai/utils";
import { stationModulesByKeyState } from "./stationModulesByKeyState";
import { getKeyFromIndices } from "../utils/getKeyFromIndices";
import { atom, useAtomValue } from "jotai";
import { Indices, StationModuleKind } from "../types";

export const stationModuleKindState = atomFamily((indices: Indices) =>
  atom<StationModuleKind>(
    (get) => get(stationModulesByKeyState)[getKeyFromIndices(indices)].kind
  )
);

export const useStationModuleKind = (indices: Indices) =>
  useAtomValue(stationModuleKindState(indices));
