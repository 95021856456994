import { atom } from "jotai";
import { StationModuleKind } from "../types";
import { atomFamily } from "jotai/utils";
import { persistentAtom } from "./utils/persistentAtom";

export const activeBrushState = persistentAtom<StationModuleKind>(
  "activeBrushState",
  "Junction"
);

export const isBrushActiveState = atomFamily((kind: StationModuleKind) =>
  atom((get) => get(activeBrushState) === kind)
);
