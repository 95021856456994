import { Box, Cylinder, Edges } from "@react-three/drei";
import { FC, useRef } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { Connector } from "./helpers/Connector";
import { MeshPhysicalMaterial } from "three";
import { useFrame } from "@react-three/fiber";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS = 12;

const ReactorPowerRing: FC = () => {
  const ref = useRef<MeshPhysicalMaterial>(null);

  useFrame(({ clock }) => {
    if (ref.current) {
      ref.current.opacity = 0.8 + Math.sin(clock.getElapsedTime()) * 0.2;
    }
  });

  return (
    <Cylinder args={[10, 10, 2, SEGMENTS, 1]}>
      <meshPhysicalMaterial
        ref={ref}
        color="#88f"
        emissive="#80ccff"
        transparent
        opacity={0.8}
      />
    </Cylinder>
  );
};

export const Reactor: FC = () => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  const gray200 = useMaterial({ color: Colors.gray200 });
  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <mesh position={[0, 8, 0]}>
        <Cylinder args={[6, 7.5, 2, SEGMENTS, 1]} material={gray100}>
          <Edges />
        </Cylinder>
      </mesh>

      <mesh position={[0, -8, 0]}>
        <Cylinder args={[8, 7.5, 2, SEGMENTS, 1]} material={gray100}>
          <Edges />
        </Cylinder>
      </mesh>

      <mesh position={[0, 0, 0]}>
        <ReactorPowerRing />
      </mesh>

      <mesh position={[0, -4, 0]}>
        <ReactorPowerRing />
      </mesh>

      <mesh position={[0, 4, 0]}>
        <ReactorPowerRing />
      </mesh>

      <mesh rotation={[0, Math.PI / 4, 0]}>
        <Box args={[15, 14, 15]} material={gray200}>
          <Edges />
        </Box>
      </mesh>

      <mesh rotation={[0, Math.PI / 6, 0]}>
        <Box args={[15.5, 13.5, 15.5]} material={gray200}>
          <Edges />
        </Box>
      </mesh>

      <mesh rotation={[0, Math.PI / 8, 0]}>
        <Box args={[16, 13, 16]} material={gray200}>
          <Edges />
        </Box>
      </mesh>

      <mesh rotation={[0, -Math.PI / 6, 0]}>
        <Box args={[15.5, 13.5, 15.5]} material={gray200}>
          <Edges />
        </Box>
      </mesh>

      <mesh rotation={[0, -Math.PI / 8, 0]}>
        <Box args={[16, 13, 16]} material={gray200}>
          <Edges />
        </Box>
      </mesh>

      <Connector />
    </>
  );
};
