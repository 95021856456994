import { Indices, NeighborIndices } from "../types";

// Directions are relative to the world coordinate system.
export const getNeighborIndices = ([x, y, z]: Indices): NeighborIndices => ({
  left: [x - 1, y, z],
  right: [x + 1, y, z],
  bottom: [x, y - 1, z],
  top: [x, y + 1, z],
  back: [x, y, z - 1],
  front: [x, y, z + 1],
});
