import { useAtomValue } from "jotai";
import { FC } from "react";
import { summaryState } from "../simulation/state";
import { BehaviorKind } from "../simulation/types";
import { map } from "lodash";
import styles from "./SummaryDisplay.module.css";
import Icon from "@mdi/react";
import { SIMULATION_ICONS } from "./constants";
import { getBehaviorSummaryMessage } from "../simulation/utils/getBehaviorSummaryMessage";
import { getBehaviorSummaryStatus } from "../simulation/utils/getBehaviorSummaryStatus";
import { formatBehaviorSummaryValue } from "../simulation/utils/formatBehaviorSummaryValue";

interface SummaryEntryProps {
  type: BehaviorKind;
  value: number;
}

const SummaryEntry: FC<SummaryEntryProps> = ({ type, value }) => {
  const status = getBehaviorSummaryStatus(type, value);
  const message = getBehaviorSummaryMessage(type, value);
  return (
    <div data-color={status}>
      <div className={styles.entry} data-uppercase>
        <div>
          <Icon path={SIMULATION_ICONS[type]} size={0.5} />
          &nbsp;
          {type}
        </div>
        <strong>{formatBehaviorSummaryValue(type, value)}</strong>
      </div>
      <small data-pulse={status === "danger"}>{message}</small>
    </div>
  );
};

export const SummaryDisplay: FC = () => {
  const summary = useAtomValue(summaryState);

  return (
    <div className={styles.container}>
      {map(summary, (value: number, type: BehaviorKind) => (
        <SummaryEntry key={type} type={type} value={value} />
      ))}
    </div>
  );
};
