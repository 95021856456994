import { atom, useStore } from "jotai";
import { Indices } from "../types";
import { atomFamily, useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";
import { isEqual } from "lodash";
import { getKeyFromIndices } from "../utils/getKeyFromIndices";

export const NO_SELECTION = undefined;

export const selectedStationModuleState = atom<Indices | undefined>(
  NO_SELECTION
);

export const isStationModuleSelectedState = atomFamily((indices: Indices) =>
  atom<boolean>((get) => isEqual(get(selectedStationModuleState), indices))
);

export const useSelectStationModule = (indices: Indices) =>
  useAtomCallback(
    useCallback(
      (_get, set) => {
        console.log(`[selection] set to ${getKeyFromIndices(indices)}`);
        set(selectedStationModuleState, indices);
      },
      [indices]
    )
  );

export const useClearSelectedStationModule = () =>
  useAtomCallback(
    useCallback((_get, set) => {
      console.log("[selection] cleared");
      set(selectedStationModuleState, NO_SELECTION);
    }, [])
  );

type OnChanged = (isSelected: boolean, indices: Indices) => void;

export const useStationModuleSelectionChanged = (
  indices: Indices,
  onChanged: OnChanged
) => {
  const store = useStore();

  useEffect(
    () =>
      store.sub(selectedStationModuleState, () => {
        const selection = store.get(selectedStationModuleState);

        onChanged(isEqual(selection, indices), indices);
      }),
    [indices, onChanged, store]
  );
};
