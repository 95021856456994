import { DEBUG } from "../../environment";

type ColorsType = {
  white: string;
  gray50: string;
  gray100: string;
  gray200: string;
  gray300: string;
  gray500: string;
  gray600: string;
  black: string;
  gold: string;
  chrome: string;
  glass: string;
};

const COLORS_NORMAL: ColorsType = {
  white: "#fff",
  gray50: "#ddd",
  gray100: "#ccc",
  gray200: "#999",
  gray300: "#777",
  gray500: "#555",
  gray600: "#444",
  black: "#111",
  gold: "#fd8",
  chrome: "#eef",
  glass: "#ded",
};

const COLORS_DEBUG: ColorsType = {
  white: "#fff",
  gray50: "#ddc",
  gray100: "#cbf",
  gray200: "#0fd",
  gray300: "#777",
  gray500: "#fd0",
  gray600: "#ec0",
  black: "#111",
  gold: "#fe0",
  chrome: "#f0f0f0",
  glass: "#ddf",
};

export const Colors = DEBUG ? COLORS_DEBUG : COLORS_NORMAL;
