import { FC, Fragment, useEffect, useRef } from "react";
import { Axes } from "./Station/helpers/Axes";
import { ModuleRenderer } from "./Station/ModuleRenderer";
import { useStation } from "../state/stationState";
import { Group } from "three";
import { useAtomValue } from "jotai";
import { areAxesVisibleState } from "../state/devToolsState";

export const Station: FC = () => {
  const { modules, rotation = [0, 0, 0] } = useStation();
  const groupRef = useRef<Group>(null);

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.rotation.set(
        rotation[0] * Math.PI,
        rotation[1] * Math.PI,
        rotation[2] * Math.PI
      );
    }
  }, [rotation]);

  const areAxesVisible = useAtomValue(areAxesVisibleState);

  return (
    <Fragment>
      <Axes size={15} forced={areAxesVisible} />

      <group ref={groupRef} scale={0.1}>
        {modules.map((module, index) => (
          <ModuleRenderer key={index} indices={module.indices} />
        ))}
      </group>
    </Fragment>
  );
};
