import { BehaviorKind } from "../types";
import { OVERHEAT } from "./thresholds";

type Status = "danger" | undefined;

export const getBehaviorSummaryStatus = (
  type: BehaviorKind,
  value: number
): Status => {
  if (type === "energy" && value < 0) {
    return "danger";
  }

  if (type === "heat" && value > OVERHEAT) {
    return "danger";
  }

  if (type === "water" && value < 0) {
    return "danger";
  }

  if (type === "atmosphere" && value < 0) {
    return "danger";
  }
};
