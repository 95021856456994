import { Box, Cylinder, Edges, Sphere } from "@react-three/drei";
import { FC, memo, useMemo } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { memoize, range } from "lodash";
import { Coordinates, Indices } from "../../types";
import * as THREE from "three";
import { FlakesTexture } from "three-stdlib";
import { ModuleComponent } from "./types";
import { Random } from "random";
import seedrandom from "seedrandom";
import { LOD } from "./helpers/LOD";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS = 12;

type Seed = {
  indices: Indices;
  index: number;
};

type Plant = {
  id: number;
  position: Coordinates;
  size: number;
  color: string;
};

const seedToString = memoize((seed: Seed) => JSON.stringify(seed));

// @ts-expect-error
const createRandom = (seed: Seed) => new Random(seedrandom(seedToString(seed)));

const getRandomPointInCylinder = (
  radius: number,
  height: number,
  seed: Seed
) => {
  const rng = createRandom(seed);
  const theta = rng.next() * 2 * Math.PI; // Random azimuthal angle (longitude)
  const y = rng.next() * height - height / 2; // Random height (up to the given height)

  const r = Math.sqrt(rng.next()) * radius; // Random radius (circular cross-section)
  const x = r * Math.cos(theta);
  const z = r * Math.sin(theta);

  return [x, y, z];
};

const getRandomPlantColor = (rng: Random): string => {
  const minGreen = 192; // Minimum green channel value to ensure it's somewhat greenish
  const maxGreen = 255; // Maximum green channel value
  const green = Math.floor(rng.next() * (maxGreen - minGreen + 1)) + minGreen;

  const blue = Math.floor(rng.next() * 128); // Random blue channel value
  const red = Math.floor(rng.next() * 128); // Random red channel value

  const hexColor = `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;
  return hexColor;
};

const getRandomSize = (seed: Seed): number => {
  const rng = createRandom(seed);
  return rng.next() + 0.5;
};

const LEAVES = new THREE.CanvasTexture(
  new FlakesTexture() as OffscreenCanvas,
  THREE.UVMapping,
  THREE.RepeatWrapping,
  THREE.RepeatWrapping
);

const GREENS = range(0, 20).map((index) => {
  const rng = new Random();
  const color = getRandomPlantColor(rng);
  return new THREE.MeshPhysicalMaterial({
    color,
    normalMap: LEAVES,
    normalScale: new THREE.Vector2(0.8, 0.8),
    metalness: 0,
    roughness: 1,
    anisotropy: 0,
  });
});

const getPlantMaterial = (seed: Seed) => {
  const rng = createRandom(seed);
  const material = rng.choice(GREENS);
  return material;
};

const ShadePanel: FC = memo(() => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <mesh position={[0, 0, 8]}>
      <Box args={[9, 20, 0.5]} material={gray100} />
    </mesh>
  );
});

export const Biome: ModuleComponent = ({ indices }) => {
  const plants = useMemo(
    () =>
      range(0, 50).map(
        (index) =>
          ({
            id: index,
            position: getRandomPointInCylinder(5, 15, { indices, index }),
            size: getRandomSize({ indices, index }),
            color: getRandomPlantColor(createRandom({ indices, index })),
          } as Plant)
      ),
    [indices]
  );

  const white = useMaterial({ color: Colors.white });
  const gray100 = useMaterial({ color: Colors.gray100 });
  const gray200 = useMaterial({ color: Colors.gray200 });
  const glass = useMaterial({ color: Colors.glass, side: 2 });

  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <group rotation={[0, Math.PI / 6, 0]}>
        <mesh rotation={[0, 0, 0]}>
          <ShadePanel />
        </mesh>

        <mesh rotation={[0, (Math.PI * 2) / 3, 0]}>
          <ShadePanel />
        </mesh>

        <mesh rotation={[0, -(Math.PI * 2) / 3, 0]}>
          <ShadePanel />
        </mesh>
      </group>

      <Cylinder args={[9, 9, 17, 6, 1, true]} material={glass}>
        <Edges />
      </Cylinder>

      <mesh position={[0, 9, 0]}>
        <Cylinder args={[9, 9, 1, 6]} material={gray100}>
          <Edges />
        </Cylinder>
      </mesh>

      <mesh position={[0, -9, 0]}>
        <Cylinder args={[9, 9, 1, 6]} material={gray100}>
          <Edges />
        </Cylinder>
      </mesh>

      <Cylinder args={[1, 1, 18, 6, 6]} material={white}>
        <Edges />
      </Cylinder>

      {plants.map((plant, index) => (
        <LOD key={plant.id}>
          <Sphere
            args={[plant.size, 7, 7]}
            position={plant.position}
            material={getPlantMaterial({ indices, index })}
          />
          <Sphere
            args={[plant.size, 5, 5]}
            position={plant.position}
            material={getPlantMaterial({ indices, index })}
          />
          <Sphere
            args={[plant.size, 2, 2]}
            position={plant.position}
            material={getPlantMaterial({ indices, index })}
          />
        </LOD>
      ))}

      <Cylinder
        position={[0, 9.5, 0]}
        args={[5, 5, 1, SEGMENTS, 1]}
        material={gray200}
      >
        <Edges />
      </Cylinder>

      <Cylinder
        position={[0, -9.5, 0]}
        args={[5, 5, 1, SEGMENTS, 1]}
        material={gray200}
      >
        <Edges />
      </Cylinder>
    </>
  );
};
