import { FC, MouseEvent, memo, useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { isPaletteOpenState } from "../state/isPaletteOpenState";
import { useAtomValue } from "jotai";
import { activeBrushState } from "../state/activeBrushState";
import { humanize } from "../utils/humanize";
import { ICONS } from "./constants";
import styles from "./TogglePaletteButton.module.css";
import { areMenusExclusive } from "../utils/areMenusExclusive";
import { isMenuOpenState } from "../state/isMenuOpenState";

export const TogglePaletteButton: FC = memo(() => {
  const onClick = useAtomCallback(
    useCallback((get, set, e: MouseEvent) => {
      e.stopPropagation();

      set(isPaletteOpenState, (isOpen) => !isOpen);

      if (get(isPaletteOpenState) && areMenusExclusive()) {
        set(isMenuOpenState, false);
      }
    }, [])
  );

  const activeBrush = useAtomValue(activeBrushState);
  const isPaletteOpen = useAtomValue(isPaletteOpenState);

  return (
    <button className={styles.layout} onClick={onClick}>
      <Icon path={ICONS[activeBrush]} size={0.7} />
      {humanize(activeBrush)}
      <span style={{ width: "100%" }} />
      <Icon path={isPaletteOpen ? mdiChevronDown : mdiChevronUp} size={0.7} />
    </button>
  );
});
