import { Station } from "../types";
import { generateStationName } from "./generateStationName";

export const createNewStation = (): Station => ({
  name: generateStationName(),
  modules: [
    {
      indices: [0, 0, 0],
      kind: "Junction",
    },
  ],
});
