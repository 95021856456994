import { FC } from "react";
import { Html } from "@react-three/drei";
import styles from "./ModuleOrientationHelper.module.css";

export const ModuleOrientationHelper: FC = () => (
  <group>
    <mesh position={[10, 0, 0]}>
      <Html>
        <div className={styles.neighborLabel}>RIGHT</div>
      </Html>
    </mesh>
    <mesh position={[-10, 0, 0]}>
      <Html>
        <div className={styles.neighborLabel}>LEFT</div>
      </Html>
    </mesh>
    <mesh position={[0, 10, 0]}>
      <Html>
        <div className={styles.neighborLabel}>TOP</div>
      </Html>
    </mesh>
    <mesh position={[0, -10, 0]}>
      <Html>
        <div className={styles.neighborLabel}>BOTTOM</div>
      </Html>
    </mesh>
    <mesh position={[0, 0, 10]}>
      <Html>
        <div className={styles.neighborLabel}>FRONT</div>
      </Html>
    </mesh>
    <mesh position={[0, 0, -10]}>
      <Html>
        <div className={styles.neighborLabel}>BACK</div>
      </Html>
    </mesh>
  </group>
);
