import {
  mdiAtom,
  mdiBlur,
  mdiBlurRadial,
  mdiCardsVariant,
  mdiCircleDouble,
  mdiHeatWave,
  mdiHvac,
  mdiLeafCircle,
  mdiLightningBolt,
  mdiMemory,
  mdiRadioTower,
  mdiTire,
  mdiTurbine,
  mdiViewGridCompact,
  mdiWater,
} from "@mdi/js";
import { StationModuleKind } from "../types";
import { BehaviorKind } from "../simulation/types";

export const ICONS: Record<StationModuleKind, string> = {
  Junction: mdiTire,
  Section: mdiBlurRadial,
  SolarPanel: mdiViewGridCompact,
  Girder: mdiMemory,
  Reactor: mdiAtom,
  Airlock: mdiHvac,
  Tank: mdiCircleDouble,
  CommunicationsArray: mdiRadioTower,
  HeatSink: mdiHeatWave,
  Biome: mdiLeafCircle,
  Engine: mdiTurbine,
  Factory: mdiCardsVariant,
  RadiationShield: mdiBlur,
};

export const SIMULATION_ICONS: Record<BehaviorKind, string> = {
  energy: mdiLightningBolt,
  heat: mdiHeatWave,
  water: mdiWater,
  atmosphere: mdiHvac,
};
