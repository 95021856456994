import { atom, useAtomValue } from "jotai";
import { activeStationIndexState } from "./activeStationIndexState";
import { stationsState } from "./stationsState";
import { Station } from "../types";
import { isFunction } from "lodash";

type Update = (station: Station) => Station;

export const stationState = atom(
  (get) => {
    const activeIndex = get(activeStationIndexState);
    return get(stationsState)[activeIndex];
  },
  (get, set, update: Station | Update) => {
    const activeIndex = get(activeStationIndexState);

    set(stationsState, (stations) =>
      stations.map((station, index) =>
        index === activeIndex
          ? isFunction(update)
            ? update(station)
            : update
          : station
      )
    );
  }
);

export const useStation = () => useAtomValue(stationState);
