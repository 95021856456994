import { groupBy, mapValues, sumBy } from "lodash";
import { Station } from "../types";
import { BEHAVIORS } from "./behaviors";
import { Behavior, BehaviorKind, SimulationSummary } from "./types";

type BehaviorEntry = [BehaviorKind, Behavior];

const getBehaviorValue = (behavior: Behavior) => {
  if (behavior.type === "producer") {
    return behavior.amount;
  }

  if (behavior.type === "consumer") {
    return -behavior.amount;
  }

  return 0;
};

export const summarize = (station: Station): SimulationSummary => {
  const behaviorsPerModule = station.modules.map(
    (module) => BEHAVIORS[module.kind]
  );

  const behaviorEntries: BehaviorEntry[] = behaviorsPerModule.flatMap(
    (bpm) => Object.entries(bpm) as BehaviorEntry[]
  );

  const behaviorsByKind = groupBy(behaviorEntries, ([key]) => key);

  return mapValues(behaviorsByKind, (behaviors) =>
    sumBy(behaviors, ([, behavior]) => getBehaviorValue(behavior))
  ) as SimulationSummary;
};
