import { Sphere } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { Connector } from "./helpers/Connector";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS = 12;

export const Junction: FC = () => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <Sphere args={[10, SEGMENTS, SEGMENTS]} material={gray100} />

      <Connector />

      <mesh rotation={[Math.PI / 2, 0, 0]}>
        <Connector />
      </mesh>

      <mesh rotation={[0, 0, Math.PI / 2]}>
        <Connector />
      </mesh>
    </>
  );
};
