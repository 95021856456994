import { atomFamily } from "jotai/utils";
import { stationModulesByKeyState } from "./stationModulesByKeyState";
import { getKeyFromIndices } from "../utils/getKeyFromIndices";
import { atom, useAtomValue } from "jotai";
import { Indices } from "../types";

export const stationModuleState = atomFamily((indices: Indices) =>
  atom((get) => get(stationModulesByKeyState)[getKeyFromIndices(indices)])
);

export const useStationModule = (indices: Indices) =>
  useAtomValue(stationModuleState(indices));
