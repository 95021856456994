import { Bloom, Noise } from "@react-three/postprocessing";
import { DEBUG } from "../environment";
import { LensFlare } from "@andersonmancini/lens-flare";
import { compact } from "lodash";
import { FC, Fragment } from "react";
import { BlendFunction } from "postprocessing";

const LENS_FLARE = false;

const Effects: FC = () => {
  const effects = [
    !DEBUG ? (
      <Bloom key="bloom" mipmapBlur luminanceThreshold={0.1} intensity={10} />
    ) : null,

    <Noise
      key="noise"
      premultiply
      blendFunction={BlendFunction.ADD}
      opacity={0.3}
    />,

    LENS_FLARE ? (
      <LensFlare
        key="lensFlare"
        dirtTextureFile={"/lensDirtTexture.png"}
        position={{
          x: -60,
          y: 0,
          z: -60,
        }}
        starBurst={false}
        flareSpeed={0.1}
      />
    ) : null,
  ];
  return <Fragment>{compact(effects)}</Fragment>;
};

export default Effects;
