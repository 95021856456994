import { FC, memo, useCallback } from "react";
import { useAtomValue } from "jotai";
import { Joystick } from "react-joystick-component";
import {
  stationRotationState,
  useUpdateRotationX,
  useUpdateRotationZ,
} from "../state/stationRotationState";
import { getCssVariable } from "../utils/getCssVariable";

type JoystickEvent = {
  x: number | null;
  y: number | null;
};

const BASE_COLOR = getCssVariable("--ui-background-active");
const STICK_COLOR = getCssVariable("--ui-color");

export const StationOrientationControls: FC = memo(() => {
  const rotation = useAtomValue(stationRotationState);

  const updateRotationX = useUpdateRotationX();
  const updateRotationZ = useUpdateRotationZ();

  const onMove = useCallback(
    (event: JoystickEvent) => {
      if (event.x) {
        updateRotationX(event.x);
      }
      if (event.y) {
        updateRotationZ(event.y);
      }
    },
    [updateRotationX, updateRotationZ]
  );

  return (
    <Joystick
      baseColor={BASE_COLOR}
      stickColor={STICK_COLOR}
      size={100}
      move={onMove}
      pos={{ x: rotation[0], y: rotation[2] }}
    />
  );
});
