declare global {
  interface Navigator {
    msMaxTouchPoints?: number;
  }
}

const getMaxTouchPoints = () =>
  navigator.maxTouchPoints ?? navigator.msMaxTouchPoints ?? 0;

export const isTouchSupported = () =>
  "ontouchstart" in window || getMaxTouchPoints() > 0;
