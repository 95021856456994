import { FC, MouseEvent, memo, useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { activeStationIndexState } from "../state/activeStationIndexState";
import { stationsState } from "../state/stationsState";

interface Props {
  name: string;
  index: number;
}

export const DeleteStationButton: FC<Props> = memo(({ name, index }) => {
  const onClick = useAtomCallback(
    useCallback(
      (_get, set, e: MouseEvent) => {
        e.stopPropagation();

        if (window.confirm(`Are you sure you want to delete ${name}?`)) {
          set(activeStationIndexState, 0);
          set(stationsState, (stations) =>
            stations.filter((_station, stationIndex) => index !== stationIndex)
          );
        }
      },
      [index, name]
    )
  );

  return (
    <button onClick={onClick}>
      <Icon path={mdiClose} size={0.7} />
    </button>
  );
});
