import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { WorldToLocalOrientation, Indices } from "../types";
import { stationModuleKindState } from "./stationModuleKindState";
import { shouldIgnoreNeighbor } from "../utils/shouldIgnoreNeighbor";
import { isEqual } from "lodash";
import { stationModuleNeighborsState } from "./stationModuleNeighborsState";

export const stationModuleOrientationState = atomFamily(
  (indices: Indices) =>
    atom<WorldToLocalOrientation>((get) => {
      const kind = get(stationModuleKindState(indices));
      const neighbors = get(stationModuleNeighborsState({ indices }));

      // Solar panels always align with neighbors front/back/left/right
      if (kind === "SolarPanel") {
        if (neighbors.front || neighbors.back) {
          return "clockwise";
        }

        return "top-to-top";
      }

      if (neighbors.top && !shouldIgnoreNeighbor(neighbors.top)) {
        return "top-to-bottom";
      }

      if (neighbors.bottom && !shouldIgnoreNeighbor(neighbors.bottom)) {
        return "top-to-top";
      }

      if (neighbors.front) {
        return "top-to-back";
      }

      if (neighbors.back) {
        return "top-to-front";
      }

      if (neighbors.left) {
        return "top-to-right";
      }

      if (neighbors.right) {
        return "top-to-left";
      }

      return "top-to-top";
    }),
  isEqual
);
