import {
  Cylinder,
  Edges,
  Lathe,
  MeshDistortMaterial,
  Sphere,
} from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import * as THREE from "three";
import { range } from "lodash";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS = 12;

const points: THREE.Vector2[] = range(0, 5).map(
  (i) => new THREE.Vector2(i * 2, i * i * 1 + i * 0.5 - 10)
);

export const Engine: FC = () => {
  const gray200 = useMaterial({ color: Colors.gray200 });
  const sphere = useMaterial({ color: Colors.gray300 });
  const cone = useMaterial({ color: Colors.gray200, side: 2 });

  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <Cylinder
        position={[0, -2.75, 0]}
        args={[7, 7, 5, SEGMENTS, 1]}
        material={gray200}
      >
        <Edges />
      </Cylinder>

      <mesh position={[0, -3, 0]}>
        <Sphere args={[7, SEGMENTS, SEGMENTS]} material={sphere} />
      </mesh>

      <Lathe args={[points, SEGMENTS]} material={cone}>
        <Edges />
      </Lathe>

      <mesh position={[0, 3, 0]}>
        <Sphere args={[5, SEGMENTS / 2, SEGMENTS / 2]}>
          <meshPhysicalMaterial color="#88f" emissive="#8bf" />
        </Sphere>
      </mesh>

      <mesh position={[0, 5, 0]}>
        <Cylinder args={[3, 5, 10, SEGMENTS * 2, 1, true]}>
          <MeshDistortMaterial
            color="#88f"
            emissive="#8bf"
            transparent
            opacity={0.2}
            roughness={0.5}
            side={2}
            speed={10}
          />
        </Cylinder>
      </mesh>

      <mesh position={[0, 6, 0]}>
        <Cylinder args={[1, 4, 15, SEGMENTS * 2, 1, true]}>
          <MeshDistortMaterial
            color="#88f"
            emissive="#8bf"
            transparent
            opacity={0.1}
            roughness={0.5}
            side={2}
            speed={5}
          />
        </Cylinder>
      </mesh>

      <mesh position={[0, 7, 0]}>
        <Cylinder args={[1, 3, 17, SEGMENTS * 2, 1, true]}>
          <MeshDistortMaterial
            color="#88f"
            emissive="#8bf"
            transparent
            opacity={0.05}
            roughness={0.5}
            side={2}
            speed={1}
          />
        </Cylinder>
      </mesh>
    </>
  );
};
