import { atom } from "jotai";
import { stationState } from "../../state/stationState";
import { summarize } from "../summarize";

export const summaryState = atom((get) => {
  const station = get(stationState);

  const summary = summarize(station);

  return summary;
});
