import { FC, MouseEvent, memo, useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { mdiShimmer } from "@mdi/js";
import Icon from "@mdi/react";
import { createNewStation } from "../utils/createNewStation";
import { stationsState } from "../state/stationsState";
import { activeStationIndexState } from "../state/activeStationIndexState";

export const NewStationButton: FC = memo(() => {
  const onClick = useAtomCallback(
    useCallback((get, set, e: MouseEvent) => {
      e.stopPropagation();

      const stations = get(stationsState);
      const newActiveIndex = stations.length;
      set(stationsState, [...stations, createNewStation()]);
      set(activeStationIndexState, newActiveIndex);
    }, [])
  );

  return (
    <button onClick={onClick}>
      <Icon path={mdiShimmer} size={0.7} /> New Station
    </button>
  );
});
