import { MeshPhysicalMaterial, Side, Vector2 } from "three";
import * as THREE from "three";
import { FlakesTexture } from "three-stdlib";
import { Colors } from "../colors";

interface Props {
  color: string;
  noEdges?: boolean;
  flatShading?: boolean;
  side?: Side;
  normalMap?: THREE.MeshPhysicalMaterialParameters["normalMap"];
  normalScale?: [number, number];
}

const FLAKES = new THREE.CanvasTexture(
  new FlakesTexture() as OffscreenCanvas,
  THREE.UVMapping,
  THREE.RepeatWrapping,
  THREE.RepeatWrapping
);

const cache: Record<string, MeshPhysicalMaterial> = {};

const getCacheKey = (props: Props) => JSON.stringify(props);

const getMaterialProperties = (
  color: string
): THREE.MeshPhysicalMaterialParameters => {
  if (color === Colors.gold) {
    return {
      clearcoat: 0.05,
      clearcoatRoughness: 0.5,
      roughness: 0.3,
      metalness: 0.2,
      iridescence: 1,
      iridescenceIOR: 1,
      iridescenceThicknessRange: [0, 300] as [number, number],
      normalMap: FLAKES,
      normalScale: new THREE.Vector2(0.25, 0.25),
      emissive: "#210",
    };
  }

  if (color === Colors.chrome) {
    return {
      emissive: "#445",
      clearcoat: 0.8,
      clearcoatRoughness: 0.1,
      roughness: 0.1,
      metalness: 0.8,
      iridescence: 1,
      iridescenceIOR: 1,
      iridescenceThicknessRange: [0, 400] as [number, number],
    };
  }

  if (color === Colors.glass) {
    return {
      transparent: true,
      opacity: 0.2,
      emissive: "#121",
      reflectivity: 0.1,
      clearcoat: 0.33,
      clearcoatRoughness: 0.2,
      roughness: 0.1,
      metalness: 0.0,
    };
  }

  return {
    emissive: "#111",
    clearcoat: 0.01,
    clearcoatRoughness: 0.9,
    roughness: 0.7,
    metalness: 0.05,
  };
};

const getNormalProperties = ({
  color,
  normalMap,
  normalScale,
}: Pick<Props, "color" | "normalMap" | "normalScale">) => {
  if (color === Colors.gold) {
    return {};
  }

  if (normalMap && normalScale) {
    return {
      normalMap,
      normalScale: new Vector2(normalScale[0], normalScale[1]),
    };
  }

  return normalMap ? { normalMap } : {};
};

export const useMaterial = (props: Props) => {
  const key = getCacheKey(props);

  if (cache[key]) {
    return cache[key];
  }

  const {
    color,
    flatShading = false,
    side = 0,
    normalMap,
    normalScale,
  } = props;

  const material = new THREE.MeshPhysicalMaterial({
    color,
    ...getNormalProperties({ color, normalMap, normalScale }),
    ...getMaterialProperties(color),
    side,
    flatShading,
  });

  cache[key] = material;

  return cache[key];
};
