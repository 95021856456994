import { Octahedron, Sphere } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { LOD } from "./helpers/LOD";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS_HIGH = 16;
const SEGMENTS_MEDIUM = 12;
const SEGMENTS_LOW = 8;

export const Tank: FC = () => {
  const white = useMaterial({ color: Colors.white });

  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <Octahedron args={[10, 2]}>
        <meshBasicMaterial
          color={Colors.gray500}
          wireframe
          wireframeLinewidth={8}
        />
      </Octahedron>

      <LOD>
        <Sphere args={[9, SEGMENTS_HIGH, SEGMENTS_HIGH]} material={white} />
        <Sphere args={[9, SEGMENTS_MEDIUM, SEGMENTS_MEDIUM]} material={white} />
        <Sphere args={[9, SEGMENTS_LOW, SEGMENTS_LOW]} material={white} />
      </LOD>
    </>
  );
};
