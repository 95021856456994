import { Line, Text } from "@react-three/drei";
import { FC } from "react";
import { DEBUG } from "../../../environment";
import { Coordinates } from "../../../types";

interface Props {
  size?: number;
  forced?: boolean;
}

const FORCE = false;
const LABEL_SCALE: Coordinates = [0.05, 0.05, 0.05];
const LABEL_OFFSET = 1.05;

export const Axes: FC<Props> = ({ size = 1, forced }) =>
  DEBUG || FORCE || forced ? (
    <group scale={size}>
      <Line
        points={[
          [0, 0, 0],
          [1, 0, 0],
        ]}
        color="#f07"
        lineWidth={2}
      />
      <group position={[LABEL_OFFSET, 0, 0]} scale={LABEL_SCALE}>
        <Text color="#f07">X</Text>
      </group>
      <Line
        points={[
          [0, 0, 0],
          [0, 1, 0],
        ]}
        color="#3f8"
        lineWidth={2}
      />
      <group position={[0, LABEL_OFFSET, 0]} scale={LABEL_SCALE}>
        <Text color="#3f8">Y</Text>
      </group>
      <Line
        points={[
          [0, 0, 0],
          [0, 0, 1],
        ]}
        color="#0bf"
        lineWidth={2}
      />
      <group position={[0, 0, LABEL_OFFSET]} scale={LABEL_SCALE}>
        <Text color="#0bf" rotation={[0, Math.PI / 2, 0]}>
          Z
        </Text>
      </group>
    </group>
  ) : null;
