import { FC, memo } from "react";
import { Coordinates, Indices } from "../../types";
import { Modules } from "./constants";
import { ModuleOrientation } from "./ModuleOrientation";
import { useStationModuleKind } from "../../state/stationModuleKindState";
import { ModuleController } from "./ModuleController";

interface Props {
  indices: Indices;
}

const transformIndexToCoordinate = (index: number): number =>
  index === 0 ? 0 : index > 0 ? index * 20 : index * 20;

const transformIndicesToCoordinates = ([x, y, z]: Coordinates): Coordinates => [
  transformIndexToCoordinate(x),
  transformIndexToCoordinate(y),
  transformIndexToCoordinate(z),
];

export const ModuleRenderer: FC<Props> = memo(({ indices }) => {
  const kind = useStationModuleKind(indices);
  const ModuleComponent = Modules[kind];

  return (
    <group position={transformIndicesToCoordinates(indices)}>
      <ModuleController indices={indices} />
      <ModuleOrientation indices={indices}>
        <ModuleComponent indices={indices} />
      </ModuleOrientation>
    </group>
  );
});
