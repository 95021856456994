import { useAtomValue } from "jotai";
import { FC } from "react";
import { areInstructionsVisibleState } from "../state/areInstructionsVisibleState";
import styles from "./InteractionInstructions.module.css";
import { activeBrushState } from "../state/activeBrushState";
import { startCase } from "lodash";
import { isTouchSupported } from "../utils/isTouchSupported";

export const InteractionInstructions: FC = () => {
  const areVisible = useAtomValue(areInstructionsVisibleState);
  const brush = useAtomValue(activeBrushState);

  const alwaysVisible = isTouchSupported();

  const style = { opacity: areVisible || alwaysVisible ? 1 : 0 };

  const verb = isTouchSupported() ? "tap" : "click";

  return (
    <div className={styles.container} style={style}>
      <small className={styles.phrase}>
        <strong data-uppercase>Double-{verb}</strong> to add a{" "}
        {startCase(brush)}.
      </small>{" "}
      <small className={styles.phrase}>
        <strong data-uppercase>Long press</strong> to remove module.
      </small>
    </div>
  );
};
