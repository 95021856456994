import { BehaviorKind } from "../types";
import { OVERHEAT } from "./thresholds";

export const getBehaviorSummaryMessage = (
  type: BehaviorKind,
  value: number
) => {
  if (type === "heat" && value > OVERHEAT) {
    return "Excess heat detected. Add heat sinks.";
  }

  if (type === "water" && value < 0) {
    return "Insufficient water detected. Add tanks.";
  }

  if (type === "atmosphere" && value < 0) {
    return "Insufficient atmosphere. Add biomes.";
  }

  if (type === "energy" && value < 0) {
    return "Insufficient energy. Add solar panels or a reactor.";
  }
};
