import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { stationModulesByKeyState } from "./stationModulesByKeyState";
import { getKeyFromIndices } from "../utils/getKeyFromIndices";
import { Indices, Neighbors, StationModule } from "../types";
import { getNeighborIndices } from "../utils/getNeighborIndices";
import { isEqual } from "lodash";
import { getNeighborsForWorldToLocalOrientation } from "../utils/getNeighborsForWorldToLocalOrientation";
import { stationModuleOrientationState } from "./stationModuleOrientationState";

type Key = {
  indices: Indices;
  relative?: "world" | "local";
};

export const stationModuleNeighborsState = atomFamily(
  ({ indices, relative }: Key) =>
    atom<Neighbors<StationModule>>((get) => {
      const stationModulesByKey = get(stationModulesByKeyState);
      const neighborIndices = getNeighborIndices(indices);

      const neighbors: Neighbors<StationModule> = {
        top: stationModulesByKey[getKeyFromIndices(neighborIndices.top)],
        bottom: stationModulesByKey[getKeyFromIndices(neighborIndices.bottom)],
        left: stationModulesByKey[getKeyFromIndices(neighborIndices.left)],
        right: stationModulesByKey[getKeyFromIndices(neighborIndices.right)],
        front: stationModulesByKey[getKeyFromIndices(neighborIndices.front)],
        back: stationModulesByKey[getKeyFromIndices(neighborIndices.back)],
      };

      if (relative === "local") {
        const orientation = get(stationModuleOrientationState(indices));
        return getNeighborsForWorldToLocalOrientation(orientation, neighbors);
      }

      return neighbors;
    }),
  isEqual
);
