import { useAtomCallback } from "jotai/utils";
import { Indices, Station, StationModule } from "../types";
import { useCallback } from "react";
import { atom } from "jotai";
import { stationState } from "./stationState";
import { activeBrushState } from "./activeBrushState";
import { add } from "../utils/indices";

export const constructModuleState = atom(null, (get, set, indices: Indices) => {
  const kind = get(activeBrushState);
  const constructed: StationModule = {
    kind,
    indices,
  };
  console.log(`[construct]`, kind, constructed);
  set(stationState, ({ modules, ...station }: Station) => ({
    ...station,
    modules: [...modules, constructed],
  }));
});

export const useConstructModule = (indices: Indices) =>
  useAtomCallback(
    useCallback(
      (_get, set, offset: Indices) => {
        set(constructModuleState, add(indices, offset));
      },
      [indices]
    )
  );
