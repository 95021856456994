import { FC, memo } from "react";
import Icon from "@mdi/react";
import { getKeyFromIndices } from "../../utils/getKeyFromIndices";
import { Indices } from "../../types";
import { useStationModule } from "../../state";
import { humanize } from "../../utils/humanize";
import { ICONS } from "../../hud/constants";
import styles from "./ModuleTooltipContent.module.css";

const FastIcon = memo(Icon);

interface Props {
  indices: Indices;
}
export const ModuleTooltipContent: FC<Props> = memo(({ indices }) => {
  const { kind } = useStationModule(indices);

  return (
    <div className={styles.container}>
      <div className={styles.tooltip}>
        <div data-header data-uppercase className={styles.header}>
          <div data-flex data-align-center>
            <FastIcon path={ICONS[kind]} size={0.5} />
            &nbsp;
            {humanize(kind)}
          </div>
          <small data-muted>{getKeyFromIndices(indices)}</small>
        </div>
      </div>
    </div>
  );
});
