import { Cylinder, Edges } from "@react-three/drei";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { Girder } from "./Girder";
import { useMaterial } from "./helpers/useMaterial";
import { ModuleComponent } from "./types";
import { useAtomValue } from "jotai";
import { stationModuleNeighborsState } from "../../state/stationModuleNeighborsState";

const SEGMENTS = 6;

type PanelArgs = [number, number, number, number, number];
const PANEL_ARGS: PanelArgs = [11, 11, 1, SEGMENTS, 1];

export const RadiationShield: ModuleComponent = ({ indices }) => {
  const neighbors = useAtomValue(
    stationModuleNeighborsState({ indices, relative: "local" })
  );
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <Cylinder args={PANEL_ARGS} material={gray100}>
        <Edges />
      </Cylinder>

      {!neighbors.right && (
        <Cylinder args={PANEL_ARGS} position={[20, 0, 0]} material={gray100}>
          <Edges />
        </Cylinder>
      )}

      {!neighbors.left && (
        <Cylinder args={PANEL_ARGS} position={[-20, 0, 0]} material={gray100}>
          <Edges />
        </Cylinder>
      )}

      {!neighbors.right && !neighbors.front && (
        <Cylinder args={PANEL_ARGS} position={[10, 0, 17]} material={gray100}>
          <Edges />
        </Cylinder>
      )}

      {!neighbors.right && !neighbors.back && (
        <Cylinder args={PANEL_ARGS} position={[10, 0, -17]} material={gray100}>
          <Edges />
        </Cylinder>
      )}

      {!neighbors.left && !neighbors.front && (
        <Cylinder args={PANEL_ARGS} position={[-10, 0, 17]} material={gray100}>
          <Edges />
        </Cylinder>
      )}

      {!neighbors.left && !neighbors.back && (
        <Cylinder args={PANEL_ARGS} position={[-10, 0, -17]} material={gray100}>
          <Edges />
        </Cylinder>
      )}

      <Girder indices={indices} />
    </>
  );
};
