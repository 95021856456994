import { Line } from "@react-three/drei";
import { FC, Fragment } from "react";
import { getCssVariable } from "../../../utils/getCssVariable";

const COLOR = getCssVariable("--ui-color");

const OUTER_SIZE = 10;
const INNER_SIZE = 8;

export const FocusIndicator: FC = () => (
  <>
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, OUTER_SIZE, OUTER_SIZE],
        [OUTER_SIZE, OUTER_SIZE, INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, OUTER_SIZE, OUTER_SIZE],
        [OUTER_SIZE, INNER_SIZE, OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, OUTER_SIZE, OUTER_SIZE],
        [INNER_SIZE, OUTER_SIZE, OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, OUTER_SIZE, OUTER_SIZE],
        [-OUTER_SIZE, OUTER_SIZE, INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, OUTER_SIZE, OUTER_SIZE],
        [-OUTER_SIZE, INNER_SIZE, OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, OUTER_SIZE, OUTER_SIZE],
        [-INNER_SIZE, OUTER_SIZE, OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, -OUTER_SIZE, OUTER_SIZE],
        [-OUTER_SIZE, -OUTER_SIZE, INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, -OUTER_SIZE, OUTER_SIZE],
        [-OUTER_SIZE, -INNER_SIZE, OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, -OUTER_SIZE, OUTER_SIZE],
        [-INNER_SIZE, -OUTER_SIZE, OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, -OUTER_SIZE, OUTER_SIZE],
        [OUTER_SIZE, -OUTER_SIZE, INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, -OUTER_SIZE, OUTER_SIZE],
        [OUTER_SIZE, -INNER_SIZE, OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, -OUTER_SIZE, OUTER_SIZE],
        [INNER_SIZE, -OUTER_SIZE, OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, OUTER_SIZE, -OUTER_SIZE],
        [OUTER_SIZE, OUTER_SIZE, -INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, OUTER_SIZE, -OUTER_SIZE],
        [OUTER_SIZE, INNER_SIZE, -OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, OUTER_SIZE, -OUTER_SIZE],
        [INNER_SIZE, OUTER_SIZE, -OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, OUTER_SIZE, -OUTER_SIZE],
        [-OUTER_SIZE, OUTER_SIZE, -INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, OUTER_SIZE, -OUTER_SIZE],
        [-OUTER_SIZE, INNER_SIZE, -OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, OUTER_SIZE, -OUTER_SIZE],
        [-INNER_SIZE, OUTER_SIZE, -OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
        [-OUTER_SIZE, -OUTER_SIZE, -INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
        [-OUTER_SIZE, -INNER_SIZE, -OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [-OUTER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
        [-INNER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
      ]}
    />

    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
        [OUTER_SIZE, -OUTER_SIZE, -INNER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
        [OUTER_SIZE, -INNER_SIZE, -OUTER_SIZE],
      ]}
    />
    <Line
      color={COLOR}
      points={[
        [OUTER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
        [INNER_SIZE, -OUTER_SIZE, -OUTER_SIZE],
      ]}
    />
  </>
);
