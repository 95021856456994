import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Canvas, RootState } from "@react-three/fiber";
import Effects from "./3d/Effects";
import { Ambience, BACKGROUND } from "./3d/Ambience";
import { Station } from "./3d/Station";
import { EffectComposer } from "@react-three/postprocessing";
import { HeadsUpDisplay } from "./hud/HeadsUpDisplay";
import { MaybeLeva } from "./dev-tools/MaybeLeva";
import { Perf } from "r3f-perf";
import { useClearSelectedStationModule } from "./state";

const onCanvasCreated = ({ gl }: RootState) => {
  gl.setClearColor(BACKGROUND);
};

const isPerfEnabled = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has("perf");
};

function App() {
  const clearSelectedStationModule = useClearSelectedStationModule();

  return (
    <div id="canvas-container">
      <MaybeLeva />

      <Canvas
        onCreated={onCanvasCreated}
        onPointerMissed={clearSelectedStationModule}
        shadows
        linear
        dpr={[1, 2]}
        gl={{ antialias: false }}
      >
        {isPerfEnabled() && <Perf position="top-left" />}

        <PerspectiveCamera makeDefault position={[15, 7, -15]} />

        <OrbitControls
          minDistance={5}
          maxDistance={100}
          enableDamping
          enablePan
          enableRotate
          enableZoom
        />

        <Ambience />

        <EffectComposer multisampling={0}>
          <Effects />
        </EffectComposer>

        <Station />
      </Canvas>

      <HeadsUpDisplay />
    </div>
  );
}

export default App;
