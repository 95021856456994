import { atom } from "jotai";
import { stationState } from "./stationState";
import { Rotation } from "../types";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { isFunction } from "lodash";

type Update<V> = (current: V) => V;

export const stationRotationState = atom(
  (get) => {
    const { rotation = [0, 0, 0] } = get(stationState);
    return rotation;
  },
  (_get, set, update: Rotation | Update<Rotation>) => {
    set(stationState, (station) => ({
      ...station,
      rotation: isFunction(update)
        ? update(station.rotation ?? [0, 0, 0])
        : update,
    }));
  }
);

export const useUpdateRotationX = () =>
  useAtomCallback(
    useCallback((_get, set, update: number | Update<number>) => {
      set(stationRotationState, ([x, y, z]) => [
        isFunction(update) ? update(x) : update,
        y,
        z,
      ]);
    }, [])
  );

export const useUpdateRotationZ = () =>
  useAtomCallback(
    useCallback((_get, set, update: number | Update<number>) => {
      set(stationRotationState, ([x, y, z]) => [
        x,
        y,
        isFunction(update) ? update(z) : update,
      ]);
    }, [])
  );
