import { Leva } from "leva";
import { FC } from "react";

export const MaybeLeva: FC = () => (
  <div
    style={{
      display: process.env.NODE_ENV === "development" ? "block" : "none",
    }}
  >
    <Leva />
  </div>
);
