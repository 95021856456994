import { Billboard } from "@react-three/drei";
import { Depth, LayerMaterial } from "lamina";
import { FC } from "react";
import * as THREE from "three";

interface Props {
  color: THREE.ColorRepresentation;
  scale?: number;
}

export const Corona: FC<Props> = ({ color, scale = 0.5 }) => {
  const size = 100;
  const near = 1;
  const far = -2;
  const alpha = 1;

  return (
    <Billboard>
      <mesh>
        <circleGeometry args={[size * scale, 64]} />
        <LayerMaterial
          transparent
          depthWrite={false}
          blending={THREE.CustomBlending}
          blendEquation={THREE.AddEquation}
          blendSrc={THREE.SrcAlphaFactor}
          blendDst={THREE.DstAlphaFactor}
        >
          <Depth
            colorA={color}
            colorB="black"
            alpha={alpha}
            mode="multiply"
            near={near * scale}
            far={far * scale}
            origin={[0, 0, 0]}
          />
        </LayerMaterial>
      </mesh>
    </Billboard>
  );
};
