import * as THREE from "three";
import { Sphere, Stars } from "@react-three/drei";
import { FC, PropsWithChildren, useRef } from "react";
import { DEBUG } from "../environment";
import { useAmbience } from "./useAmbience";
import { Corona } from "./Corona";

export const BACKGROUND = DEBUG
  ? new THREE.Color("#ffffff")
  : new THREE.Color("#020230");

const SUN_POSITION = 395;

export const Ambience: FC<PropsWithChildren> = ({ children }) => {
  const groupRef = useRef<THREE.Group>(null);
  const glowRef = useRef<THREE.Group>(null);
  useAmbience((rotationY) => {
    if (groupRef.current) {
      groupRef.current.rotation.y = rotationY;
    }
    if (glowRef.current) {
      glowRef.current.rotation.y = -rotationY;
    }
  });

  return (
    <group userData={{ lensflare: "no-occlusion" }} ref={groupRef}>
      <Stars
        radius={200}
        depth={100}
        count={5000}
        factor={6}
        saturation={0.1}
        fade
        speed={0.01}
      />

      <mesh position={[SUN_POSITION, 0, SUN_POSITION]}>
        <Sphere scale={2}>
          <meshBasicMaterial color={[20, 16, 10]} toneMapped={false} />
        </Sphere>
        <group ref={glowRef}>
          <Corona color="#fed" scale={1} />
        </group>
      </mesh>

      <ambientLight intensity={DEBUG ? 0.5 : 0.2} color="#ddf" />
      <spotLight
        position={[SUN_POSITION, 0, SUN_POSITION]}
        angle={0.15}
        penumbra={10}
        castShadow
      />
      <pointLight
        position={[SUN_POSITION, 0, SUN_POSITION]}
        intensity={DEBUG ? 150000 : 200000}
        castShadow
      />

      {/* <Planets /> */}
    </group>
  );
};
