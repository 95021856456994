import { Box, Circle, Plane } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import * as THREE from "three";
import { Dimensions } from "./helpers/Dimensions";
import { range } from "lodash";
import { Connector } from "./helpers/Connector";
import { useMaterial } from "./helpers/useMaterial";

const VANES = 8;
const SEGMENTS = VANES * 2;
const RADIUS = 9;

const VANE = new THREE.MeshPhysicalMaterial({
  side: 2,
  color: "#822",
  emissive: "#b42",
});

export const HeatSink: FC = () => {
  const braces = useMaterial({ color: Colors.gray200 });
  const gray200 = useMaterial({ color: Colors.gray200, side: 2 });
  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      {range(0, VANES).map((index) => (
        <group key={index} rotation={[0, (index * Math.PI) / VANES, 0]}>
          <Plane args={[15, RADIUS * 2, 1, 1]} material={VANE} />
          <Box
            args={[2, 20, 0.5]}
            position={[RADIUS - 1, 0, 0]}
            material={braces}
          />
          <Box
            args={[2, 20, 0.5]}
            position={[-(RADIUS - 1), 0, 0]}
            material={braces}
          />
        </group>
      ))}

      <group rotation={[Math.PI / 2, 0, 0]}>
        <Circle
          args={[RADIUS, SEGMENTS]}
          position={[0, 0, 9]}
          material={gray200}
        />
        <Circle
          args={[RADIUS, SEGMENTS]}
          position={[0, 0, -9]}
          material={gray200}
        />
      </group>

      <Connector />
    </>
  );
};
