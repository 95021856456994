import { Behaviors, StationModuleBehaviors } from "./types";

const INERT: Behaviors = {
  energy: {
    type: "inert",
  },
  heat: {
    type: "inert",
  },
  water: {
    type: "inert",
  },
  atmosphere: {
    type: "inert",
  },
};

const createBehaviors = (definition: Partial<Behaviors>): Behaviors => ({
  ...INERT,
  ...definition,
});

export const BEHAVIORS: StationModuleBehaviors = {
  Reactor: createBehaviors({
    energy: {
      type: "producer",
      amount: 5,
    },
    heat: {
      type: "producer",
      amount: 5,
    },
  }),

  SolarPanel: createBehaviors({
    energy: {
      type: "producer",
      amount: 1,
    },
  }),

  HeatSink: createBehaviors({
    energy: {
      type: "consumer",
      amount: 2,
    },
    heat: {
      type: "consumer",
      amount: 10,
    },
    water: {
      type: "consumer",
      amount: 2,
    },
  }),

  Section: createBehaviors({
    energy: {
      type: "consumer",
      amount: 2,
    },
    heat: {
      type: "producer",
      amount: 2,
    },
    water: {
      type: "consumer",
      amount: 2,
    },
    atmosphere: {
      type: "consumer",
      amount: 5,
    },
  }),

  Biome: createBehaviors({
    energy: {
      type: "consumer",
      amount: 1,
    },
    heat: {
      type: "producer",
      amount: 1,
    },
    water: {
      type: "consumer",
      amount: 5,
    },
    atmosphere: {
      type: "producer",
      amount: 5,
    },
  }),

  Tank: createBehaviors({
    energy: {
      type: "consumer",
      amount: 1,
    },
    heat: {
      type: "consumer",
      amount: 1,
    },
    water: {
      type: "producer",
      amount: 5,
    },
  }),

  Factory: createBehaviors({
    energy: {
      type: "consumer",
      amount: 3,
    },
    heat: {
      type: "producer",
      amount: 1,
    },
  }),

  Airlock: INERT,
  Junction: INERT,
  Girder: INERT,
  Engine: INERT,
  CommunicationsArray: INERT,
  RadiationShield: INERT,
};
