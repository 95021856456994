import { sample, startCase } from "lodash";

const ADJECTIVES = [
  "cybernetic",
  "interstellar",
  "alien",
  "futuristic",
  "hyperspace",
  "nano",
  "android",
  "stellar",
  "virtual",
  "cosmic",
  "teleporting",
  "quantum",
  "extraterrestrial",
  "neural",
  "laser",
  "time-traveling",
  "robotic",
  "bioengineered",
  "plasma",
  "dimensional",
  "genetically-enhanced",
  "astral",
  "cyberpunk",
  "hybrid",
  "intergalactic",
  "gravitational",
  "hyperspeed",
  "telepathic",
  "nanotechnological",
  "dystopian",
  "holo",
  "antigravity",
  "cloning",
  "metamorphic",
  "infrared",
  "synthetic",
  "telekinetic",
  "psionic",
  "subspace",
  "pulsar",
  "mutation",
  "paradoxical",
  "photon",
  "warp",
  "singularity",
  "cyberspace",
  "exo",
  "ionized",
];

const PLACES = [
  "station",
  "outpost",
  "colony",
  "asteroid",
  "planet",
  "moon",
  "spaceport",
  "hub",
  "settlement",
  "megacity",
  "hive",
  "dome",
  "metropolis",
  "shuttle",
  "starbase",
  "enclave",
  "spacestation",
  "observatory",
  "coliseum",
  "garden",
  "ark",
  "megatower",
  "quadrant",
  "arcology",
  "biosphere",
  "habitat",
  "wasteland",
  "void",
  "gateway",
  "nexus",
  "fortress",
  "citadel",
  "laboratory",
  "facility",
  "sanctuary",
  "dystopia",
  "skyplex",
  "institute",
  "jungle",
  "cyberspace",
  "dimension",
  "rift",
  "chasm",
  "sector",
  "starship",
  "refuge",
  "oasis",
  "spires",
  "undercity",
];

export const generateStationName = () =>
  startCase(
    [
      sample(ADJECTIVES),
      sample(PLACES),
      (Math.random() * 1000).toFixed(0),
    ].join(" ")
  );
