import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { Coordinates, Indices } from "../types";
import { isEqual } from "lodash";
import { getRotationForWorldToLocalOrientation } from "../utils/getRotationForWorldToLocalOrientation";
import { stationModuleOrientationState } from "./stationModuleOrientationState";

export const stationModuleRotationState = atomFamily(
  (indices: Indices) =>
    atom<Coordinates>((get) => {
      const orientation = get(stationModuleOrientationState(indices));
      return getRotationForWorldToLocalOrientation(orientation);
    }),
  isEqual
);
