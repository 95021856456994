import { memo } from "react";
import { StationModuleKind } from "../../types";
import { Section } from "./Section";
import { Junction } from "./Junction";
import { Airlock } from "./Airlock";
import { Reactor } from "./Reactor";
import { SolarPanel } from "./SolarPanel";
import { Girder } from "./Girder";
import { Tank } from "./Tank";
import { CommunicationsArray } from "./CommunicationsArray";
import { HeatSink } from "./HeatSink";
import { Biome } from "./Biome";
import { Engine } from "./Engine";
import { Factory } from "./Factory";
import { ModuleComponent } from "./types";
import { RadiationShield } from "./RadiationShield";

export const Modules: Record<StationModuleKind, ModuleComponent> = {
  Section: memo(Section),
  Junction: memo(Junction),
  Airlock: memo(Airlock),
  SolarPanel: memo(SolarPanel),
  Reactor: memo(Reactor),
  Girder: memo(Girder),
  Tank: memo(Tank),
  CommunicationsArray: memo(CommunicationsArray),
  HeatSink: memo(HeatSink),
  Biome: memo(Biome),
  Engine: memo(Engine),
  Factory: memo(Factory),
  RadiationShield: memo(RadiationShield),
};
