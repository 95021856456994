import { FC } from "react";
import { OpenStationButton } from "./OpenStationButton";
import styles from "./StationMenuItem.module.css";
import { DeleteStationButton } from "./DeleteStationButton";

interface Props {
  name: string;
  index: number;
}

export const StationMenuItem: FC<Props> = ({ name, index }) => (
  <div className={styles.container}>
    <OpenStationButton name={name} index={index} />

    <DeleteStationButton name={name} index={index} />
  </div>
);
