import { Sphere } from "@react-three/drei";
import { FC } from "react";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { Connector } from "./helpers/Connector";
import { useMaterial } from "./helpers/useMaterial";

const SEGMENTS = 12;

export const Airlock: FC = () => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <Sphere args={[10, SEGMENTS, SEGMENTS]} material={gray100} />

      <Connector />
    </>
  );
};
