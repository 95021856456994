import { Neighbors, WorldToLocalOrientation } from "../types";

export const getNeighborsForWorldToLocalOrientation = <T>(
  orientation: WorldToLocalOrientation,
  neighbors: Neighbors<T>
): Neighbors<T> => {
  if (orientation === "top-to-top") {
    return {
      top: neighbors.top,
      bottom: neighbors.bottom,
      front: neighbors.front,
      back: neighbors.back,
      left: neighbors.left,
      right: neighbors.right,
    };
  }

  if (orientation === "top-to-bottom") {
    return {
      top: neighbors.bottom,
      bottom: neighbors.top,
      front: neighbors.front,
      back: neighbors.back,
      left: neighbors.right,
      right: neighbors.left,
    };
  }

  if (orientation === "top-to-right") {
    return {
      top: neighbors.right,
      bottom: neighbors.left,
      front: neighbors.front,
      back: neighbors.back,
      left: neighbors.top,
      right: neighbors.bottom,
    };
  }

  if (orientation === "top-to-left") {
    return {
      top: neighbors.left,
      bottom: neighbors.right,
      front: neighbors.front,
      back: neighbors.back,
      left: neighbors.bottom,
      right: neighbors.top,
    };
  }

  if (orientation === "top-to-front") {
    return {
      top: neighbors.front,
      bottom: neighbors.back,
      front: neighbors.bottom,
      back: neighbors.top,
      left: neighbors.left,
      right: neighbors.right,
    };
  }

  if (orientation === "top-to-back") {
    return {
      top: neighbors.back,
      bottom: neighbors.front,
      front: neighbors.top,
      back: neighbors.bottom,
      left: neighbors.left,
      right: neighbors.right,
    };
  }

  if (orientation === "clockwise") {
    return {
      top: neighbors.top,
      bottom: neighbors.bottom,
      front: neighbors.left,
      back: neighbors.right,
      left: neighbors.back,
      right: neighbors.front,
    };
  }

  throw new Error(`Invalid orientation: ${orientation}`);
};
