import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { Coordinates, Indices } from "../../types";
import { useAtomValue } from "jotai";
import { stationModuleRotationState } from "../../state/stationModuleRotationState";

interface Props {
  indices: Indices;
}

interface ContextProps {
  rotation: Coordinates;
}
const ModuleOrientationContext = createContext<ContextProps>({
  rotation: [0, 0, 0],
});

export const useModuleOrientation = () => useContext(ModuleOrientationContext);

export const ModuleOrientation: FC<PropsWithChildren<Props>> = ({
  children,
  indices,
}) => {
  const rotation = useAtomValue(stationModuleRotationState(indices));
  const contextValue = useMemo(() => ({ rotation }), [rotation]);

  return (
    <group rotation={rotation}>
      <ModuleOrientationContext.Provider value={contextValue}>
        {children}
      </ModuleOrientationContext.Provider>
    </group>
  );
};
