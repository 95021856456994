import moize from "moize";
import { Rotation, WorldToLocalOrientation } from "../types";

export const getRotationForWorldToLocalOrientation = moize(
  (orientation: WorldToLocalOrientation): Rotation => {
    if (orientation === "top-to-top") {
      return [0, 0, 0];
    }

    if (orientation === "top-to-bottom") {
      return [Math.PI, 0, 0];
    }

    if (orientation === "top-to-right") {
      return [0, 0, -Math.PI / 2];
    }

    if (orientation === "top-to-left") {
      return [0, 0, Math.PI / 2];
    }

    if (orientation === "top-to-front") {
      return [Math.PI / 2, 0, 0];
    }

    if (orientation === "top-to-back") {
      return [-Math.PI / 2, 0, 0];
    }

    if (orientation === "clockwise") {
      return [0, -Math.PI / 2, 0];
    }

    throw new Error(`Invalid orientation: ${orientation}`);
  }
);
