import { BehaviorKind } from "../types";

export const formatBehaviorSummaryValue = (
  type: BehaviorKind,
  value: number
) => {
  if (type === "heat") {
    return Math.max(0, value);
  }

  return value;
};
