import { Box, Cone, Cylinder, Edges, Text } from "@react-three/drei";
import { Colors } from "./colors";
import { Dimensions } from "./helpers/Dimensions";
import { ModuleComponent } from "./types";
import { toHex } from "../../utils/toHex";
import * as THREE from "three";
import { FC } from "react";
import { Connector } from "./helpers/Connector";
import { useMaterial } from "./helpers/useMaterial";

const TEXT_MATERIAL = new THREE.MeshStandardMaterial({
  color: Colors.black,
});

const TextPanel: ModuleComponent = ({ indices }) => {
  const gray300 = useMaterial({ color: Colors.gray300 });

  return (
    <group position={[8.7, 0, 0]}>
      <group position={[0, -6, 0]}>
        <Cone args={[0.4, 0.3, 4]} material={gray300} />

        <spotLight power={0.1} position={[0.1, -0.2, 0]} color="#feb" />
      </group>

      <Text
        position={[0, -7.3, 0]}
        rotation={[0, Math.PI / 2, 0]}
        material={TEXT_MATERIAL}
        font="monospace"
        textAlign="left"
        fontSize={0.66}
      >
        FACTORY
      </Text>
      <Text
        position={[0, -8.2, 0]}
        rotation={[0, Math.PI / 2, 0]}
        material={TEXT_MATERIAL}
        font="monospace"
        textAlign="left"
      >
        {toHex(indices.join(""))}
      </Text>
    </group>
  );
};

const StructurePanel: FC = () => {
  const gray100 = useMaterial({ color: Colors.gray100 });
  return (
    <group position={[8.7, 0, 0]}>
      <Box args={[0.2, 8, 3]} material={gray100}>
        <Edges />
      </Box>
    </group>
  );
};

export const Factory: ModuleComponent = ({ indices }) => {
  const gray100 = useMaterial({ color: Colors.gray100, flatShading: true });

  return (
    <>
      <Dimensions x={20} y={20} z={20} />

      <mesh>
        <Cylinder args={[10, 10, 18, 6, 1]} material={gray100}>
          <Edges />
        </Cylinder>

        <TextPanel indices={indices} />
        <group rotation={[0, (Math.PI * 2) / 3, 0]}>
          <TextPanel indices={indices} />
        </group>
        <group rotation={[0, -(Math.PI * 2) / 3, 0]}>
          <TextPanel indices={indices} />
        </group>

        <group rotation={[0, Math.PI, 0]}>
          <StructurePanel />
        </group>

        <group rotation={[0, (Math.PI * 5) / 3, 0]}>
          <StructurePanel />
        </group>

        <group rotation={[0, -(Math.PI * 5) / 3, 0]}>
          <StructurePanel />
        </group>
      </mesh>

      <Connector />
    </>
  );
};
