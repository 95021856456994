import { Line } from "@react-three/drei";
import { FC } from "react";
import { DEBUG } from "../../../environment";

interface Props {
  x: number;
  y: number;
  z: number;
}

export const Dimensions: FC<Props> = ({ x, y, z }) =>
  DEBUG ? (
    <mesh>
      <Line
        points={[
          [-x / 2, -y / 2, -z / 2],
          [-x / 2, y / 2, -z / 2],
        ]}
      />
      <Line
        points={[
          [x / 2, -y / 2, -z / 2],
          [x / 2, y / 2, -z / 2],
        ]}
      />
      <Line
        points={[
          [-x / 2, -y / 2, z / 2],
          [-x / 2, y / 2, z / 2],
        ]}
      />
      <Line
        points={[
          [x / 2, -y / 2, z / 2],
          [x / 2, y / 2, z / 2],
        ]}
      />

      <Line
        points={[
          [x / 2, -y / 2, -z / 2],
          [-x / 2, -y / 2, -z / 2],
        ]}
      />

      <Line
        points={[
          [x / 2, -y / 2, z / 2],
          [-x / 2, -y / 2, z / 2],
        ]}
      />

      <Line
        points={[
          [x / 2, y / 2, -z / 2],
          [-x / 2, y / 2, -z / 2],
        ]}
      />

      <Line
        points={[
          [x / 2, y / 2, z / 2],
          [-x / 2, y / 2, z / 2],
        ]}
      />

      <Line
        points={[
          [-x / 2, -y / 2, z / 2],
          [-x / 2, -y / 2, -z / 2],
        ]}
      />

      <Line
        points={[
          [x / 2, -y / 2, z / 2],
          [x / 2, -y / 2, -z / 2],
        ]}
      />

      <Line
        points={[
          [-x / 2, y / 2, z / 2],
          [-x / 2, y / 2, -z / 2],
        ]}
      />

      <Line
        points={[
          [x / 2, y / 2, z / 2],
          [x / 2, y / 2, -z / 2],
        ]}
      />
    </mesh>
  ) : null;
