import { FC, Fragment } from "react";
import styles from "./HeadsUpDisplay.module.css";
import { ToggleMenuButton } from "./ToggleMenuButton";
import { MenuOverlay } from "./MenuOverlay";
import { useAtomValue } from "jotai";
import { isMenuOpenState } from "../state/isMenuOpenState";
import { InteractionInstructions } from "./InteractionInstructions";
import { isPaletteOpenState } from "../state/isPaletteOpenState";
import { PaletteOverlay } from "./PaletteOverlay";
import { TogglePaletteButton } from "./TogglePaletteButton";

export const HeadsUpDisplay: FC = () => {
  const isMenuOpen = useAtomValue(isMenuOpenState);
  const isPaletteOpen = useAtomValue(isPaletteOpenState);

  return (
    <Fragment>
      <div className={styles.container}>
        <InteractionInstructions />
        <div className={styles.layout}>
          <TogglePaletteButton />
          <ToggleMenuButton />
        </div>
      </div>

      {isPaletteOpen && <PaletteOverlay />}
      {isMenuOpen && <MenuOverlay />}
    </Fragment>
  );
};
