import { FC, memo } from "react";
import styles from "./MenuOverlay.module.css";
import { useAtomValue } from "jotai";
import { NewStationButton } from "./NewStationButton";
import { stationsListState } from "../state/stationsListState";
import { StationMenuItem } from "./StationMenuItem";
import { StationOrientationControls } from "./StationOrientationControls";
import { DevTools } from "./DevTools";

export const MenuOverlay: FC = memo(() => {
  const stationsList = useAtomValue(stationsListState);

  return (
    <div className={styles.container}>
      <div>
        <div data-header>
          <strong data-uppercase>Station Keeper</strong>
        </div>
        <small data-muted data-uppercase>
          A 3D toy created by{" "}
          <a href="https://cubicle6.com" target="_blank" rel="noreferrer">
            Caleb Peterson
          </a>
        </small>
      </div>
      <NewStationButton />

      <div className={styles.group}>
        <div data-uppercase>Open Station</div>
        {stationsList.map((name, index) => (
          <StationMenuItem key={index} index={index} name={name} />
        ))}
      </div>

      <div className={styles.group}>
        <div data-uppercase>Orientation</div>
        <div className={styles.joysticks}>
          <StationOrientationControls />
        </div>
      </div>

      <div className={styles.group}>
        <div data-uppercase>DevTOols</div>
        <DevTools />
      </div>
    </div>
  );
});
